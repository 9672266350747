import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import SeoLeftColumn from "../../components/SeoLeftColumn";
import SeoRightColumn from "../../components/SeoRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// 1. define Hreflang data, for href tags and language button
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/seo"
);


const Seo = ({ location }) => { // Receive location as a prop
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title="Handleidingen over SEO & SEO Tools"
          lang="nl"
          description="Verhoog je SEO-vaardigheden met tutorials over SEO en doe-het-zelf SEO-tools. Beheers zoekwoordonderzoek, on-page optimalisatie en meer met onze stapsgewijze gidsen."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
        />
        <MainFrontpage>
          <SeoLeftColumn />
          <SeoRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Seo);
